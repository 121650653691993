<template>
  <div>
    跳转中
    <!-- <van-button @click="btn">进价</van-button>
    <van-button @click="order">订单列表</van-button> -->
  </div>
</template>

<script>
import { getGzhCode } from "../../api/login/login";
import { getDate } from "../../utils/LocalStorage";
export default {
  methods: {
    // 进价
    btn() {
      this.$router.push({
        path: "/incomingParts",
        params: {},
      });
    },
    // 订单列表
    order() {
      this.$router.push({
        path: "/orderRecord",
        params: {},
      });
    },
    // 获取code
    login() {
      let url = JSON.parse(getDate()).url;
      console.log(url);
      // let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe26d94dd1fbe96a9&redirect_uri=http://192.168.0.153:8080/incomingParts&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      this.code = this.getUrlCode().code;
      // 如果没有code 去获取code
      window.location.href = url;
      if (this.code == null) {
        window.location.href = url;
      } else {
        // 获取到code后的逻辑
        console.log("code", this.code);
        // code 发送到后台
        // getGzhCode({ code: this.code }).then((res) => {
        //   console.log(res);
        // });
      }
    },
    //截取code
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
  created() {
    this.login();
  },
};
</script>

<style></style>
